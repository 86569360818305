<template>
  <pharmaceutic-profile-component @update-title="changeTitle" />
</template>

<script>
import PharmaceuticProfileComponent from "@/components/pharmaceutics/PharmaceuticProfileComponent";

export default {
  name: "PharmaceuticProfile",
  title: "Perfil de Farmacia | Baja California Health Tourism",
  components: { PharmaceuticProfileComponent },
  methods: {
    changeTitle(e) {
      this.title = e;
      document.title = `${e} | Baja California Health Tourism`;
    }
  }
};
</script>

<style scoped></style>
